import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import './App.css';
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import women from './assets/images/women2.jpg'
import drop from './assets/images/drop.svg'
import poket from './assets/images/packshot.png'
import img1 from './assets/images/img1.jpg'
import img2 from './assets/images/img2.jpg'
import img3 from './assets/images/img3.jpg'
import img4 from './assets/images/img4.jpg'
import logo from './assets/images/logo.svg'
import play from './assets/images/play.png'
import FsLightbox from "fslightbox-react";

function App() {
    const [toggler,setToggler] = useState(false)
    const [index,setindex] = useState(0)

    const show = (index:any) => {
        setindex(index)
        setToggler(!toggler)
    }
    return (
    <div className="App bg position-relative" >
        <Container fluid  className={"position-relative gx-0"} style={{backgroundColor:"#c4bec0"}} >
            <Row className={"position-relative w-100  align-items-md-start    top-0 start-0"}   >
                <Col md={6} xs={5} className={""}><Image srcSet={women} className={" position-relative"} style={{zIndex:1,width:"52vw"}}  /></Col>
                <Col md={6} xs={7} className={"d-block d-md-none"} style={{zIndex:3}}>
                    <div className={"title"} style={{zIndex:1,right:0}}>
                        <p className={"fw-bold h3 m-0 p-0 text-center"}>انتقلوا إلى سيميلاك</p>
                        <h1 className={"fw-bold m-0 text-center"}>
                            مع تركيبة
                            <br />
                            الــ Triple <span>الفريدة</span>
                        </h1>
                    </div>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col xxl={6} xl={8} className={"mt-4 d-none d-md-block"}  style={{zIndex:3}}>
                            <div className={"title"} style={{zIndex:1}}>
                                <p className={"fw-bold h3 m-0 p-0 text-center"}>انتقلوا إلى سيميلاك</p>
                                <h1 className={"fw-bold m-0 text-center"}>
                                    مع تركيبة
                                    <br />
                                    الــ Triple <span>الفريدة</span>
                                </h1>
                            </div>
                        </Col>
                        <Col xxl={6} xl={4}></Col>
                        <Col md={4} xs={4} className={"position-relative text-md-start text-center"}><Image srcSet={drop} className={"position-relative drop"} style={{zIndex:3}}  /></Col>
                        <Col md={8} xs={8} className={"text-md-start text-end"} >
                            <div className={"text-md-center text-end position-relative box"} style={{zIndex:3}}>

                                <Button target={"_blank"} href={"https://shop.super-pharm.co.il/search?av=%D7%A1%D7%99%D7%9E%D7%99%D7%9C%D7%90%D7%A7+%D7%92%D7%95%D7%9C%D7%93+%D7%AA%D7%A8%D7%9B%D7%95%D7%91%D7%AA+%D7%9E%D7%96%D7%95%D7%9F+%D7%9C%D7%AA%D7%99%D7%A0%D7%95%D7%A7%D7%95%D7%AA+%D7%A2%D7%9D+%D7%A0%D7%95%D7%A1%D7%97%D7%AA+%D7%94%D7%98%D7%A8%D7%99%D7%A4%D7%9C+%D7%94%D7%99%D7%99%D7%97%D7%95%D7%93%D7%99%D7%AA"} className={"d-md-inline-block d-none"} variant={"primary"}>للشراء أونلاين  &gt;</Button>
                                <Button href={"/coupon"} className={"mb-4 d-md-inline-block d-none"} variant={"danger"}>للحصول على القسيمة &gt;</Button>
                                <Image srcSet={poket}  width={"100%"}   />
                            </div>
                        </Col>

                    </Row>
                </Col>

            </Row>
        </Container>
      <Container   className={"content  position-relative "} style={{zIndex:2,marginTop:-60}}>
          <Row className={"h-100 justify-content-end align-items-end position-relative"} style={{zIndex:1}}>
              <Col md={12} className={"text-center pt-2 mb-3"} style={{zIndex:3,position:"sticky",top:0,left:0}}>
                  <Button target={"_blank"} href={"https://shop.super-pharm.co.il/search?av=%D7%A1%D7%99%D7%9E%D7%99%D7%9C%D7%90%D7%A7+%D7%92%D7%95%D7%9C%D7%93+%D7%AA%D7%A8%D7%9B%D7%95%D7%91%D7%AA+%D7%9E%D7%96%D7%95%D7%9F+%D7%9C%D7%AA%D7%99%D7%A0%D7%95%D7%A7%D7%95%D7%AA+%D7%A2%D7%9D+%D7%A0%D7%95%D7%A1%D7%97%D7%AA+%D7%94%D7%98%D7%A8%D7%99%D7%A4%D7%9C+%D7%94%D7%99%D7%99%D7%97%D7%95%D7%93%D7%99%D7%AA"} className={"d-md-none d-inline-block"} variant={"primary"}>للشراء أونلاين  &gt;</Button>
                  <Button href={"/coupon"}  className={" d-md-none d-inline-block"} variant={"danger"}>للحصول على القسيمة &gt;</Button>
              </Col>

              <Col md={12}>
                  <Row className={"h-100 justify-content-end align-items-end text-center"}>
                      <Col lg={3} md={6} className={"mb-3 video"}>
                          <a href={"#"} onClick={()=>{show(3)}}>
                              <p className={"my-0"}>تمنح جميع احتياجات الطفل الغذائية</p>
                              <div className={"position-relative "}>
                                  <Image srcSet={play} width={50} className={"position-absolute translate-middle top-50 start-50"} />
                                  <Image srcSet={img1} width={"100%"} alt={""}/>
                              </div>
                          </a>
                      </Col>
                      <Col lg={3} md={6} className={"mb-3 video"}>
                          <a href={"#"} onClick={()=>{show(1)}}>
                              <p className={"my-0"}>تساهم في الحماية المناعيّة وزيادة الجراثيم المفيدة</p>
                              <div className={"position-relative "}>
                                  <Image srcSet={play} width={50} className={"position-absolute translate-middle top-50 start-50"} />
                                  <Image srcSet={img2} width={"100%"} alt={""}/>
                              </div>
                          </a>
                      </Col>
                      <Col lg={3} md={6} className={"mb-3 video"}>
                          <a href={"#"} onClick={()=>{show(2)}}>
                             <p className={"my-0"}>سهلة الهضم</p>
                              <div className={"position-relative "}>
                                  <Image srcSet={play} width={50} className={"position-absolute translate-middle top-50 start-50"} />
                                  <Image srcSet={img3} width={"100%"} alt={""}/>
                              </div>
                         </a>
                      </Col>
                      <Col lg={3} md={6} className={"mb-3 video"}>
                          <a href={"#"} onClick={()=>{show(0)}}>
                              <p className={"my-0"}>تساهم في التطوّر المعرفي لدى الطفل</p>
                              <div className={"position-relative "} >
                                  <Image srcSet={play} width={50} className={"position-absolute translate-middle top-50 start-50"} />
                                  <Image srcSet={img4} width={"100%"} alt={""}/>
                              </div>
                          </a>
                      </Col>

                      <Col md={12} className={"mt-md-3"}>
                          <p>للانتباه، حليب الأم هو الغذاء الأفضل للطفل. HMO من نوع <span className={"en"}>2</span>'FL هو أحد مركبات عائلة الـ HMO الموجودة في حليب الأم، ولا يتم إنتاجه منه.</p>
                      </Col>

                      <Col md={12} className={"py-md-1 pt-2"} style={{borderTop:"2px solid #000"}}>
                          <p><Image srcSet={logo} height={20} /> تمّ إنتاجه من قبل شركة أدوية دوليّة للمنتجات الصحية، ويستند إلى أكثر من <span className={"en"}>300</span> دراسة علميّة حول العالم</p>
                      </Col>
                  </Row>
              </Col>
          </Row>
      </Container>
        <FsLightbox toggler={toggler} sourceIndex={index}  sources={["https://youtu.be/iHlURxk6pB8","https://youtu.be/xgP66hayr5U","https://youtu.be/We_QnSJBLAg","https://youtu.be/8s1EREl668c"]} />

    </div>
  );
}

export default App;
